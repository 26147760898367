import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import data from '../data/data.json';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Portofolio = () => {
  const [portofolioData, setPortofolioData] = useState(null);
  const query = useQuery();
  const id = query.get('id');

  useEffect(() => {
    if (data && data.Portofolios) {
      const selectedPortofolio = data.Portofolios.find(p => p.id.toString() === id);
      setPortofolioData(selectedPortofolio);
    }
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (portofolioData) {
      document.title = `${portofolioData.title} - Meet Production`;
      const metaDescription = document.querySelector("meta[name='description']");
      if (metaDescription) {
        metaDescription.setAttribute("content", portofolioData.description);
      }
    }
  }, [portofolioData]);

  if (!portofolioData) {
    return (
      <div className="loading-container">
        <img src="/img/loading-spinner.gif" alt="Loading..." />
      </div>
    );
  }

  return (
    <Container fluid>
      <nav className="navbar-porto">
        {/* Tombol Kembali */}
        <button onClick={() => window.history.back()} className="back-btn">
          &lt;
        </button>
        <a href="https://www.meetproduction.com/">
        	<img src="/img/LOGO.png" alt="Meet Production" className="logo" loading="lazy" />
        </a>
      </nav>

      {/* Gambar Utama */}
      <div className="main-image">  
          <img src={portofolioData.mainImage} alt="Main Visual" className="img-fluid" loading="lazy" />
      </div>

      {/* Penjelasan */}
      <section className="description">
        <h1>{portofolioData.title}</h1>
        <h2>{portofolioData.date}</h2>
        <p>{portofolioData.description}</p>
      </section>

      {/* Galeri Foto */}
      <section className="gallery">
        <h3>Our Documentation</h3>
        <Row>
          {portofolioData.gallery.map((image, index) => (
            <Col xs={12} md={4} key={index}>
              <img 
                src={image} 
                alt={`Portfolio documentation ${index + 1}`} 
                className="gallery-img" 
                loading="lazy"
              />
            </Col>
          ))}
        </Row>
      </section>
    </Container>
  );
};

export default Portofolio;
