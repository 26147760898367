import React, { useState, useEffect } from "react";
import { Carousel, Container } from "react-bootstrap";
import { Helmet } from "react-helmet";

export const Header = (props) => {
  const [animateOnce, setAnimateOnce] = useState(false);

  useEffect(() => {
    // Memulai animasi hanya sekali ketika komponen dimuat
    setTimeout(() => {
      setAnimateOnce(true);
    }, 500); // Optional delay sebelum memulai animasi
  }, []); // Hanya dijalankan sekali ketika komponen dimuat

  const images = [
    { src: "../img/jumbotron/jb1.webp", alt: "Deskripsi gambar 1" },
    { src: "../img/jumbotron/jb2.webp", alt: "Deskripsi gambar 2" },
    { src: "../img/jumbotron/jb3.webp", alt: "Deskripsi gambar 3" },
    { src: "../img/jumbotron/jb4.webp", alt: "Deskripsi gambar 4" },
    { src: "../img/jumbotron/jb5.webp", alt: "Deskripsi gambar 5" },
    { src: "../img/jumbotron/jb6.webp", alt: "Deskripsi gambar 6" }
  ];

  return (
    <>
      <Helmet>
        <title>{props.data ? props.data.title : "Meet Production"}</title>
        <meta
          name="description"
          content={props.data ? props.data.paragraph : "Deskripsi default untuk Meet Production"}
        />
        <meta name="keywords" content="Meet Production, Exhibition, Event Design, Stand Design, Indonesia" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <header id="header">
        <Container fluid className="p-0">
          <Carousel controls={false} indicators={false} interval={3000} fade>
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <div
                  className="d-block w-100 carousel-bg"
                  style={{
                    backgroundImage: `url(${image.src})`,
                  }}
                >
                  <div className="overlay"></div>
                  {/* Tambahkan animasi hanya saat pertama kali */}
                  <div className={`intro-text ${animateOnce && index === 0 ? "animate" : ""}`}>
                    <h1>{props.data ? props.data.title : "Loading"}</h1>
                    <p>{props.data ? props.data.paragraph : "Loading"}</p>
                    <a href="#about" className="btn btn-custom btn-lg page-scroll">
                      Learn More
                    </a>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Container>
      </header>
    </>
  );
};
