import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";

export const Testimonials = () => {
  const topLogos = ["img/logo/asean.jpg", "img/logo/kemenparekraf.jpg", "img/logo/adwi.jpg", "img/logo/kpr.jpg", "img/logo/g20.jpg", "img/logo/jabar.jpg", "img/logo/iklas.jpg", "img/logo/yogja.jpg"];
  const bottomLogos = ["img/logo/kpu.jpg", "img/logo/tut.jpg", "img/logo/kominfo.jpg", "img/logo/golkar.jpg", "img/logo/garuda.jpg", "img/logo/kadin.jpg", "img/logo/alva.jpg", "img/logo/telkomsel.jpg"];

  const clientNamesTop = ["ASEAN", "Kemenparekraf", "ADWI", "KPR", "G20", "Jabar", "Iklas", "Yogja"];
  const clientNamesBottom = ["KPU", "TUT", "Kominfo", "Golkar", "Garuda", "Kadin", "Alva", "Telkomsel"];

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("testimonials");
      const rect = section.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      if (rect.top <= windowHeight * 0.75 && rect.bottom >= 0) {
        setIsVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getClientName = (index, type) => {
    return type === "top" ? clientNamesTop[index] : clientNamesBottom[index];
  };

  return (
    <div id="testimonials">
      <div className="section-title text-center">
        <h2 className={isVisible ? "animate" : ""}>Meet Production's Clients</h2>
        <section className="enable-animation">
          <div className="marquee">
            <ul className="marquee__content">
              {topLogos.map((logo, index) => (
                <div key={`top-logo-${index}`} className="marquee__item">
                  <img src={logo} alt={`Logo of ${getClientName(index, "top")}`} loading='lazy'/>
                </div>
              ))}
            </ul>

            <ul aria-hidden="true" className="marquee__content">
              {topLogos.map((logo, index) => (
                <div key={`top-logo-${index}`} className="marquee__item">
                  <img src={logo} alt="" loading='lazy' aria-hidden="true"/>
                </div>
              ))}
            </ul>
          </div>
          <div className="marquee marquee--reverse">
            <ul className="marquee__content bottom">
              {bottomLogos.map((logo, index) => (
                <div key={`bottom-logo-${index}`} className="marquee__item">
                  <img src={logo} alt={`Logo of ${getClientName(index, "bottom")}`} loading='lazy'/>
                </div>
              ))}
            </ul>

            <ul aria-hidden="true" className="marquee__content bottom">
              {bottomLogos.map((logo, index) => (
                <div key={`bottom-logo-${index}`} className="marquee__item">
                  <img src={logo} alt="" loading='lazy' aria-hidden="true"/>
                </div>
              ))}
            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};
