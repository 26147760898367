import React, { useState, useEffect, useRef } from "react";
import CloseButton from "react-bootstrap/CloseButton";

export const Services = (props) => {
  const [selectedService, setSelectedService] = useState(null);
  const [hasAnimated, setHasAnimated] = useState(false);
  const servicesRef = useRef(null);

  const handleCardClick = (service) => {
    setSelectedService(service);
  };

  const closePopup = () => {
    setSelectedService(null);
  };

  const row1 = props.data ? props.data.slice(0, 4) : [];
  const row2 = props.data ? props.data.slice(4) : [];

  useEffect(() => {
    const handleScroll = () => {
      const element = servicesRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const inView = rect.top < window.innerHeight && rect.bottom >= 0;
        if (inView && !hasAnimated) {
          setHasAnimated(true);
          window.removeEventListener("scroll", handleScroll); // Remove event listener after animation
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasAnimated]);

  useEffect(() => {
    if (selectedService) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [selectedService]);

  return (
    <div
      id="services"
      className={`text-center ${hasAnimated ? 'animate' : ''}`}
      ref={servicesRef}
    >
      <div className="container">
        <div className="section-title">
          <h1>
            WE MAKE EVERY EVENT <span className="highlight">TANGIBLE</span>
          </h1>
          <p className="meet">MEET PRODUCTION SERVICES</p>
        </div>
        <div className="row">
          {/* Service Cards - First Row */}
          <div className="d-flex justify-content-center flex-wrap first-row">
            {row1.map((d, i) => (
              <div
                key={`${d.name}-${i}`}
                className="col-xs-12 col-md-3 col-6 service-card-wrapper"
                onClick={() => handleCardClick(d)}
              >
                <div className="service-card">
                  <img
                    src={`img/${d.image}`}
                    alt={`${d.name} - Meet Production Service`}
                    className="img-fluid"
                    loading="lazy" // Tambahkan atribut loading="lazy"
                  />
                  <div className="overlay">
                    <h3>{d.name}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Service Cards - Second Row */}
          <div className="d-flex justify-content-center flex-wrap second-row">
            {row2.map((d, i) => (
              <div
                key={`${d.name}-${i}`}
                className="col-xs-12 col-md-3 col-6 service-card-wrapper"
                onClick={() => handleCardClick(d)}
              >
                <div className="service-card">
                  <img
                    src={`img/${d.image}`}
                    alt={`${d.name} - Meet Production Service`}
                    className="img-fluid"
                    loading="lazy" // Tambahkan atribut loading="lazy"
                  />
                  <div className="overlay">
                    <h3>{d.name}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedService && (
        <div className="overlay-bg" onClick={closePopup}>
          <div className="popup" onClick={(e) => e.stopPropagation()}>
            <div className="icon">
              <CloseButton onClick={closePopup} aria-label="Close" />
            </div>
            <img
              src={`img/${selectedService.image}`}
              alt={`${selectedService.name} - Meet Production Service`}
              loading="lazy" // Tambahkan atribut loading="lazy"
            />
            <div className="popup-content">
              <div className="popup-text">
                <h2>{selectedService.name}</h2>
                <p>{selectedService.text}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
